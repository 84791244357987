import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';

ReactDOM.render(
  <App options_list={{
    "is": ["oldest", "youngest", "tallest", "shortest"],
    "has": ["biggest wingspan", "shortest wingspan", "longest hair", "shortest hair", "longest socks", "shortest socks"],
    "slept": ["most", "least"],
    "woke up": ["most recent", "longest ago"],
    "ate": ["most recent", "longest ago"],
    "travelled": ["shortest", "furthest"],
    "arrived": ["earliest", "latest"],
    "kept their whole costume on": ["shortest", "longest"],
    "had their birthday": ["most recently", "longest ago"],
    "partied": ["hardest"],
    "won": ["least", "most"]
  }} />,
  document.getElementById('root')
);
