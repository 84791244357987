import React from 'react';
import {Howl, Howler} from 'howler';
import StarDistributor from './StarDistributor';

import BG from './images/wp1907519-mario-bros-wallpapers.jpg';


class MusicManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mute: true};
  }

  componentDidMount() {
    this.setVolume();

    import('./music/smw_ovr1.mp3').then(({default: Music}) => {
      this.music = new Howl({src: [Music]});
      this.music.loop(true);
      this.setVolume();
    });
  }

  setVolume() {
    if (this.music) {
      if (this.state.mute) {
        this.music.pause();
      } else {
        this.music.play();
      }
    }

    Howler.mute(this.state.mute);
  }

  toggleAudio() {
    this.setState({mute: !this.state.mute}, this.setVolume);
  }

  render() {
    return (
      <button
        className="text-black text-opacity-70 hover:text-opacity-100"
        data-testid="audio"
        onClick={() => this.toggleAudio()}>{this.state.mute ? '🔇' : '🎵'}</button>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      started: Array(3).fill(false),
      next: 0,
      bgpos: 0,
      options: Object.assign({}, this.props.options_list),
    };
    this.celebrations_list = [];
  }

  componentDidMount() {
    const pushCelebration = ({default: Celebration}) => {
      this.celebrations_list.push(new Howl({src: Celebration}));
      this.setState({celebrations: this.celebrations_list.slice()});
    };

    import('./sfx/sm64_mario_haha.wav').then(pushCelebration);
    import('./sfx/sm64_mario_hoo.wav').then(pushCelebration);
    import('./sfx/sm64_mario_hoohoo.wav').then(pushCelebration);
    import('./sfx/sm64_mario_lets_go.wav').then(pushCelebration);
    import('./sfx/sm64_mario_waha.wav').then(pushCelebration);
    import('./sfx/sm64_mario_whoa.wav').then(pushCelebration);
    import('./sfx/sm64_mario_yahoo.wav').then(pushCelebration);
    import('./sfx/sm64_mario_yippee.wav').then(pushCelebration);
    import('./sfx/sm64_yoshi.wav').then(pushCelebration);

    document.addEventListener("keyup", (event) => {
      if (["Enter", " "].includes(event.key)) {
        this.runNext();
      }
    });

    this.timerId = setInterval(() => this.moveBg(-0.2), 16);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  reset() {
    this.setState({
      started: Array(3).fill(false),
      next: 0,
      options: Object.assign({}, this.props.options_list),
      celebrations: this.celebrations_list.slice(),
    });
  }

  moveBg(distance) {
    this.setState({bgpos: this.state.bgpos + distance});
  }

  runNext() {
    const next = this.state.next;

    if (next < this.state.started.length) {
      let started = this.state.started.slice();
      started[next] = true;
      this.setState({
        started,
        next: next + 1,
      });
    }
  }

  removeOption(option) {
    let options = this.state.options;
    delete options[option];
    this.setState({options});
  }

  removeCelebration(celebration) {
    let celebrations = this.state.celebrations.slice();
    celebrations = celebrations.filter(item => item !== celebration);
    this.setState({celebrations})
  }

  render() {
    return (
      <div 
        className="flex flex-col absolute inset-0"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${BG})`,
          backgroundSize: 'auto',
          backgroundPosition: `${this.state.bgpos}px 100%`,
          backgroundRepeat: 'repeat-x',
        }} >
        <div className="flex flex-col flex-grow">
          <h1 className="xl:text-8xl lg:text-6xl sm:text-4xl text-2xl text-center p-6">The bonus stars go to...</h1>
          <div className="flex-auto grid grid-rows-3 grid-cols-1">

            {this.state.started.map((started, idx) => (
              started ?
              <div className="flex flex-row px-8">
                <h1 className="xl:text-8xl lg:text-6xl sm:text-4xl text-2xl flex-shrink align-middle text-center my-auto">⭐</h1>
                <StarDistributor
                  key={idx}
                  started={started}
                  options={this.state.options}
                  onSelectOption={(option) => this.removeOption(option)}
                  celebrations={this.state.celebrations}
                  onSelectCelebration={(celebration) => this.removeCelebration(celebration)} />
              </div> : 
              (
                idx === 0 || this.state.started[idx - 1] ?
                (
                  <div key={idx} className="text-center m-auto">
                    <button onClick={() => this.runNext()}>
                      <h1
                        className="transform lg:text-9xl md:text-8xl text-6xl text-green-600 hover:scale-110"
                        data-testid="continue"
                        style={{
                          'WebkitTextStroke': 'black',
                          'WebkitTextStrokeWidth': '2px',
                        }}>
                          &gt;
                      </h1>
                    </button>
                  </div>
                ) :
                <div key={idx} />
              )
            ))}
          </div>
        </div>
        <div className={`flex lg:text-6xl text-4xl p-2`}>
          <MusicManager />
          <div className="flex-grow" />
          <button
            className={`text-black text-opacity-70 hover:text-opacity-100 ${this.state.next >= this.state.started.length || 'hidden'}`}
            data-testid="reset"
            onClick={() => this.reset()}>🔄</button>
        </div>
      </div>
    );
  }
}

export default App;
