import React from 'react';
import {Howl} from 'howler';

class StarDistributor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      finished: false,
      selector: "...",
      definer: "...",
    };
  }

  componentDidMount() {
    import('./sfx/smw_coin.wav').then(({default: Coin}) => {
      this.coin = new Howl({src: [Coin]});
    });
    this.timerId = null;
    if (this.props.started) {
      this.Start();
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  distribute() {
    import('lodash.sample').then(({default: sample}) => {
      const selector = sample(Object.keys(this.props.options));
      const definer = sample(this.props.options[selector]);
      const limit = 6000;

      this.setState({selector, definer});
      this.coin.play();

      const elapsed = Date.now() - this.state.start;
      let delay;
      if (elapsed < limit) {
        delay = limit - elapsed < 1000 ? 1000 : elapsed / 10;
      } else {
        this.setState({finished: true});
        this.props.onSelectOption(selector);

        const celebration = sample(this.props.celebrations);
        if (celebration) {
          celebration.play();
          this.props.onSelectCelebration(celebration);
        }
      }

      if (!this.state.finished) {
        this.timerId = setTimeout(() => this.distribute(), delay);
      }
    });
  }

  Start() {
    this.setState({start: Date.now()}, this.distribute);
  }

  componentDidUpdate() {
    if (this.props.started && !this.state.start) {
      this.Start();
    }
  }

  render() {
    const finished = this.state.finished;
    const smallText = finished ? 'xl:text-6xl lg:text-4xl sm:text-2xl text-lg' : 'xl:text-4xl lg:text-2xl sm:text-xl text-lg';
    const bigText = finished ? 'xl:text-8xl lg:text-6xl sm:text-4xl text-2xl' : 'xl:text-6xl lg:text-4xl sm:text-2xl text-xl';
    return (
      <span className="align-middle text-center m-auto flex-grow">
        <h1 className={`inline ${smallText}`}>The person who</h1>
        <h1 className={`inline ${bigText}`}> {this.state.selector}</h1>
        <h1 className={`inline ${smallText}`}> the</h1>
        <h1 className={`inline ${bigText}`}> {this.state.definer}</h1>
      </span>
    );
  }
}

export default StarDistributor;
